import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "en-contact",
  "title": "Contact us",
  "seo": {
    "title": "-",
    "descr": "-"
  },
  "bg": "/img/bg-about.jpg",
  "emailForm": {
    "nameField": "Name",
    "emailField": "E-mail",
    "phoneField": "Phone",
    "messageField": "Message",
    "buttonText": "Send"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      